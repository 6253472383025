import { isMobile } from './../../helpers/detect-device';

export default () => {

	const openBtn = document.querySelector('.js-open-booking');
	const footer = document.querySelector('.js-footer');
	const roomHero = document.querySelector('.js-room-hero');

	if (!openBtn) return;

	toggleBtnVisibility();
	window.addEventListener('scroll', toggleBtnVisibility);

	function toggleBtnVisibility() {

		if (footer) {

			const buttonBottom = openBtn.getBoundingClientRect().bottom;
			const footerTop = footer.getBoundingClientRect().top;

			if (buttonBottom > footerTop) {
				openBtn.classList.add('is-invisible');
			} else {
				openBtn.classList.remove('is-invisible');
			}
		}

		if (roomHero && isMobile()) {

			const buttonTop = openBtn.getBoundingClientRect().top;
			const heroBottom = roomHero.getBoundingClientRect().bottom;

			if (buttonTop < heroBottom) {
				openBtn.classList.add('is-invisible');
			} else {
				openBtn.classList.remove('is-invisible');
			}

		}

	}

}
