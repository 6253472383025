import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobile } from './../../helpers/detect-device';

export default () => {
	gsap.registerPlugin(ScrollTrigger);

	const fadedSel = '.js-fade-anim';
	const contSel = '.js-anim-container';
	const instantlySel = '.js-instantly';
	const noMobileSel = '.js-no-mobile';

	const fadedElems = document.querySelectorAll(fadedSel);
	const containers = document.querySelectorAll(contSel);

	const defaultStart = "top 60%";
	const instantlyStart = "top 100%";

	function elemShow(elem) {
		elem.classList.remove('is-invisible');

		if (elem.matches('.js-open-booking')) {
			elem.classList.remove('js-fade-anim');
		}
	}

	if (fadedElems.length) {
		fadedElems.forEach(elem => {

			if (elem.closest(contSel)) return;

			const isInstantly = elem.matches(instantlySel);

			ScrollTrigger.create({
				trigger: elem,
				start: isInstantly ? instantlyStart : defaultStart,
				once: true,
				onEnter: () => {
					elemShow(elem);
				}
			})

		});
	}

	if (containers.length) {
		containers.forEach(container => {

			const isInstantly = container.matches(instantlySel);
			const noMobile = container.matches(noMobileSel);

			if (noMobile && isMobile()) {
				runAnimation()
			} else {
				ScrollTrigger.create({
					trigger: container,
					start: isInstantly ? instantlyStart : defaultStart,
					once: true,
					onEnter: runAnimation
				})
			}

			function runAnimation() {
				const faded = container.querySelectorAll(fadedSel);

				faded.forEach(elem => {
					elemShow(elem);
				});
			}

		});
	}

}
