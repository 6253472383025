import setFullHeight from './helpers/setFullHeight';
import { isIOS } from './helpers/detect-device';
import header from './components/common/header';
import menu from './components/common/menu';
import cookies from './components/common/cookies';
import adsBanner from './components/common/ads-banner';
import logoLottie from './components/animations/logo-lottie';

export default (pageTransDur, namespace) => {
	if (isIOS()) document.body.classList.add('is-ios');

	setFullHeight(); //Set VH variable for mobile safari 100VH, use scss mixin fullheight()
	header();
	menu();
	cookies();
	adsBanner();

	if (sessionStorage.getItem('animLogo')) {
		document.querySelector('.js-logo-svg').classList.add('header__logo--visible');
		document.querySelector('.js-lottie-container').classList.add('is-hidden');
	}

	const timeout = namespace == 'homePage' ? pageTransDur + 2600 : 10;

	setTimeout(logoLottie, timeout);
};
