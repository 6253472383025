import { gsap } from 'gsap';
import { isMobile } from './../../helpers/detect-device';

export default () => {

	const burger = document.querySelector('.js-burger');
	const menu = document.querySelector('.js-menu');
	const menuList = menu.querySelector('.js-menu-list');
	const menuLinks = menu.querySelectorAll('.js-menu-link');
	const opensSublists = menu.querySelectorAll('.js-open-sublist');
	const sublists = menu.querySelectorAll('.js-menu-sublist');
	const mobileSublists = menu.querySelectorAll('.js-sublist-mobile');

	burger.addEventListener('click', () => {
		const popUp = document.querySelector('.js-booking');
		if (!popUp || popUp.matches('.is-invisible')) {

			if (!menu.matches('.menu--opened')) {
				menu.classList.add('menu--opened');
				document.body.classList.add('no-scroll');

				sublists.forEach(list => {
					list.style.height = menuList.offsetHeight + 'px';
				});
			}
			else {
				closeMenu();
			}

		}
	})

	opensSublists.forEach(openSublist => {
		openSublist.addEventListener('click', () => {

			opensSublists.forEach(otherOpen => {
				if (otherOpen == openSublist) return;
				otherOpen.classList.remove('menu__link--active');
				otherOpen.classList.add('menu__link--inactive');
			});

			menuLinks.forEach(link => {
				link.classList.toggle('menu__link--inactive');
			});

			openSublist.classList.toggle('menu__link--active');

			if (!isMobile()) {

				const selector = openSublist.getAttribute('data-sublist');
				const currSublist = menu.querySelector(selector);

				sublists.forEach(otherSublist => {
					if (otherSublist == currSublist) return;
					otherSublist.classList.remove('menu__sub-list--active');
				});

				currSublist.classList.toggle('menu__sub-list--active');

			} else {

				const currSublist = openSublist.parentElement.querySelector('.js-sublist-mobile');

				mobileSublists.forEach(otherSublist => {
					if (otherSublist == currSublist) return;
					gsap.to(otherSublist, {
						height: 0,
					})
				});

				const startHeight = currSublist.offsetHeight;
				let currHeight = 0;

				if (startHeight == 0) {

					gsap.set(currSublist, {
						height: 'auto'
					})
					currHeight = currSublist.offsetHeight;

					gsap.fromTo(currSublist, {
						height: 0
					}, {
						height: currHeight
					})

				} else {

					gsap.to(currSublist, {
						height: 0
					})

				}

			}

		})
	});

}

export function closeMenu() {
	const menu = document.querySelector('.js-menu');
	const opensSublists = menu.querySelectorAll('.js-open-sublist');
	const menuLinks = menu.querySelectorAll('.js-menu-link');
	const sublists = menu.querySelectorAll('.js-menu-sublist');

	menu.classList.remove('menu--opened');
	document.body.classList.remove('no-scroll');

	opensSublists.forEach(openSublist => {
		openSublist.classList.remove('menu__link--active');
		openSublist.classList.remove('menu__link--inactive');
	});

	menuLinks.forEach(link => {
		link.classList.remove('menu__link--inactive');
	});

	sublists.forEach(otherSublist => {
		otherSublist.classList.remove('menu__sub-list--active');
	});
}
