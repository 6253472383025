import barba from '@barba/core';
import pageTransition from './components/animations/page-transitions';
import onceScripts from './once';
import commonScripts from './common';
import animScripts from './animations';
import { closeMenu } from './components/common/menu';

/**
* Barba initial script. Details see on link.
* @see https://wiki.bsgdigital.com/ru/onboarding/developer/front-end/template/barba
*/
export default function () {
	/**
	* Duration of page transition IN and page transition OUT
	* @constant
	* @type { number } - miliseconds
	*/
	const PAGE_IN_DUR = 1000;
	const PAGE_OUT_DUR = 500;

	const header = document.querySelector('.js-header');

	barba.init({
		debug: true, /** Set to false, when deploy to production */
		sync: true,
		prevent: ({ el }) => !!el.closest('#wpadminbar'), /** Fix for WP */
		transitions: [{
			/** Fire scripts when page leave */
			async leave(data) {
				const done = this.async();

				/** Run page out animation */
				pageTransition(true, PAGE_OUT_DUR);

				/** Close menu */
				document.querySelector('.js-header').classList.remove('header--menu-opened');
				closeMenu();

				/** Scroll window to the top */
				window.scrollTo({
					top: 0,
					behavior: 'auto'
				});

				// Write here other scripts before page leave

				await delay(PAGE_OUT_DUR);
				done();
			},
			/** Fire scripts once on page load */
			once(data) {
				/** Write here */
				let namespace = data.next.namespace;
				onceScripts(PAGE_IN_DUR, namespace);
				commonScripts();
				pageTransition(false, PAGE_IN_DUR);
				animScripts(PAGE_IN_DUR);
			},
			beforeEnter(data) {
				let namespace = data.next.namespace;
				document.body.dataset.pageName = namespace;
			},
			/** Fire scripts after page enter */
			async after(data) {
				const done = this.async();

				/** Run page in animation */
				pageTransition(false, PAGE_IN_DUR);

				await delay(PAGE_IN_DUR);
				done();

				/** Run other scripts after pageIn transition complete */
				commonScripts();
				animScripts(PAGE_IN_DUR);
			},
		}],
		/** Run scripts for each separate page after page load */
		views: [
			{
				namespace: 'homePage',
				beforeEnter: () => {
					header.classList.add('is-invisible');
					setTimeout(() => {
						header.classList.add('js-fade-anim');
					}, 10)
				},
				beforeLeave: () => {
					header.classList.remove('js-fade-anim');
				}
			},
			{
				namespace: 'stayPage',
				beforeEnter: () => {
					header.querySelector('.js-stay-link').classList.add('header__link--active');
				},
				beforeLeave: () => {
					header.querySelector('.js-stay-link').classList.remove('header__link--active');
				}
			},
			{
				namespace: 'dinePage',
				beforeEnter: () => {
					header.querySelector('.js-dine-link').classList.add('header__link--active');
				},
				beforeLeave: () => {
					header.querySelector('.js-dine-link').classList.remove('header__link--active');
				}
			},
			{
				namespace: 'eventsPage',
				beforeEnter: () => {
					header.querySelector('.js-events-link').classList.add('header__link--active');
				},
				beforeLeave: () => {
					header.querySelector('.js-events-link').classList.remove('header__link--active');
				}
			},
			{
				namespace: 'contactPage',
				beforeEnter: () => {
					header.querySelector('.js-contact-link').classList.add('header__link--active');
				},
				beforeLeave: () => {
					header.querySelector('.js-contact-link').classList.remove('header__link--active');
				}
			},
		]
	})

	/**
	* Set delay during page transition that equals to page transition duration.
	* @param { number } [n=2000] - Time in miliseconds of transition duration.
	*/
	function delay(n) {
		n = n || 2000;
		return new Promise((done) => {
			setTimeout(() => {
				done();
			}, n);
		});
	}

}
