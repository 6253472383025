import Plyr from 'plyr';

export default () => {

	const plyrBgs = document.querySelectorAll('.js-plyr-bg');
	if (!plyrBgs.length) return;

	plyrBgs.forEach(plyrBg => {

		const sourceEl = plyrBg.querySelector('iframe');
		let dataSrc = sourceEl.getAttribute('data-src');
		sourceEl.src = dataSrc;

		const player = new Plyr(plyrBg, {
			controls: [],
			loadSprite: false,
			muted: true,
			autoplay: true,
			autopause: false,
			ratio: '16:9',
			clickToPlay: false,
			storage: {enabled: false},
			vimeo: {
				keyboard: false,
				loop: true,
				muted: true,
				autoplay: true,
				portrait: false,
				title: false
			}
		});

		player.on('ready', (event) => {
			const instance = event.detail.plyr;
			instance.muted = true;
			instance.play();
		});

		setRatioHeight();
		window.addEventListener('resize', setRatioHeight);

		function setRatioHeight() {
			const videoEmbed = plyrBg.querySelector('.plyr__video-wrapper');
			const ratio = 16 / 9;
			videoEmbed.style.width = videoEmbed.offsetHeight * ratio + 'px';
		}

	});

};
