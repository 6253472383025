import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isSmallTablet, isMobile } from './../../helpers/detect-device';

export default function setParallax() {
	gsap.registerPlugin(ScrollTrigger);

	const downSel = '.js-parallax-down';
	const upSel = '.js-parallax-up';
	const downHeroSel = '.js-parallax-down-hero';
	const upHeroSel = '.js-parallax-up-hero';
	const withinSel = '.js-parallax-whithin';
	const withinHeroSel = '.js-parallax-hero';

	const parallaxWrappers = document.querySelectorAll(`${downSel}, ${upSel}, ${downHeroSel}, ${upHeroSel}, ${withinSel}, ${withinHeroSel}`);

	if (!parallaxWrappers.length) return;

	parallaxWrappers.forEach(wrapper => {

		const photo = wrapper.querySelector('.js-parallax');

		const start = wrapper.matches(`${downHeroSel}, ${upHeroSel}, ${withinHeroSel}`) ? "top top" : "top bottom";

		ScrollTrigger.create({
			trigger: wrapper,
			start: start,
			end: "bottom top",
			onUpdate: self => {
				let maxMove;
				let defaultMaxMove;

				if (isMobile()) {
					defaultMaxMove = 100;
				}
				else if (isSmallTablet()) {
					defaultMaxMove = 150;
				} else {
					defaultMaxMove = 200;
				}

				if (wrapper.matches(downSel)) {
					maxMove = defaultMaxMove;
				}
				else if (wrapper.matches(downHeroSel)) {
					maxMove = defaultMaxMove / 2;
				}
				else if (wrapper.matches(upSel)) {
					maxMove = -defaultMaxMove;
				}
				else if (wrapper.matches(upHeroSel)) {
					maxMove = -defaultMaxMove / 2;
				}
				else if (wrapper.matches(`${withinSel}, ${withinHeroSel}`))
				{
					maxMove = (photo.offsetHeight - wrapper.offsetHeight);
				}

				const move = maxMove  * self.progress;
				gsap.to(photo, {
					y: move
				})
			}
		})

	});

}
