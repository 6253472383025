import { setCookie, getCookie } from './../../helpers/cookie-scripts';

export default () => {

	const banner = document.querySelector('.js-ads-banner');
	if (!banner) return;

	const close = banner.querySelector('.js-close-ads-banner');

	if (!getCookie('adsClosed')) {
		banner.classList.remove('is-hidden');
	}

	close.addEventListener('click', () => {
		banner.classList.add('is-hidden');

		const today = new Date();
		setCookie('adsClosed', 'true', new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14))
	})

}
