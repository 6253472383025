import scrollAnimation from './components/animations/scroll-animation';
import parallax from './components/animations/parallax';

export default (pageTransDur) => {

	const loadDelay = pageTransDur * 2/3;

	setTimeout(() => {
		setTimeout(scrollAnimation, loadDelay);
		parallax();
	}, 10)

}
