import lottie from 'lottie-web';

export default () => {

	const animLogo = sessionStorage.getItem('animLogo');
	const lottieContainer = document.querySelector('.js-lottie-container');
	if (animLogo) return;

	getNewLottieAnim(lottieContainer);
	sessionStorage.setItem('animLogo', true);

}

function getNewLottieAnim(el) {
	return lottie.loadAnimation({
		container: el,
		renderer: 'svg',
		loop: false,
		autoplay: true,
		speed: 1,
		path: getLottiePath(el),
	});
}

function getLottiePath(el) {
	const path = el.getAttribute('data-lottie-src');
	return path;
}
