import { gsap } from 'gsap';
import { isTouch } from './../../helpers/detect-device';

/**
 * Creates custom cursor
 * @class
 * How to use
 * @example
 * const cursor = new Cursor();
 * cursor.init();
 */
export default class {
	constructor() {
		this.cursor = document.querySelector('.js-cursor');
		this.figureDrag = this.cursor.querySelector('.js-cursor-drag');
		this.figureNav = this.cursor.querySelector('.js-cursor-nav');
		this.hoveredNav = document.querySelectorAll('.js-cursor-nav-add');
		this.hoveredNavLeft = document.querySelectorAll('.js-cursor-nav-left-add');
		this.hoveredNavRight = document.querySelectorAll('.js-cursor-nav-right-add');
		this.hoveredDrag = document.querySelectorAll('.js-cursor-drag-add');
		this.cursorLeftPosition = document.documentElement.clientWidth / 2;
		this.cursorTopPosition = document.documentElement.clientHeight / 2;
	}

	hideDefaultCursor() {
		if (document.querySelector('#hide-default-cursor')) return;
		const style = document.createElement('style');
		style.setAttribute('id', 'hide-default-cursor');
		style.innerHTML = '* {cursor: none !important;}';
		document.body.appendChild(style);
	}

	showDefaultCursor() {
		if (!document.querySelector('#hide-default-cursor')) return;
		const style = document.querySelector('#hide-default-cursor');
		style.remove();
	}

	showCustomCursor() {
		this.cursor.classList.remove('is-invisible');
	}

	hideCustomCursor() {
		this.cursor.classList.add('is-invisible');
	}

	showCursorFigureDrag() {
		setTimeout(() => {
			this.figureDrag.classList.remove('is-invisible');
		}, 10)
	}

	hideCursorFigureDrag() {
		this.figureDrag.classList.add('is-invisible');
	}

	showCursorFigureNav() {
		setTimeout(() => {
			this.figureNav.classList.remove('is-invisible');
		}, 10)
	}

	hideCursorFigureNav() {
		this.figureNav.classList.add('is-invisible');
	}

	/** Move cursor element on page */
	moveCursor() {
		document.addEventListener('mousemove', e => {
			this.cursorLeftPosition = e.clientX;
			this.cursorTopPosition = e.clientY;

			gsap.set(this.cursor, {
				x: this.cursorLeftPosition,
				y: this.cursorTopPosition
			});
		});
	}

	cursorNavOver() {
		this.hideDefaultCursor();
		this.showCustomCursor();
		this.showCursorFigureNav();
	}

	cursorNavLeftOver() {
		document.querySelector('.js-cursor-nav-left').classList.remove('is-invisible');
		document.querySelector('.js-cursor-nav-right').classList.add('is-invisible');
	}

	cursorNavRightOver() {
		document.querySelector('.js-cursor-nav-left').classList.add('is-invisible');
		document.querySelector('.js-cursor-nav-right').classList.remove('is-invisible');
	}

	cursorNavOut() {
		this.hideCursorFigureNav();
		setTimeout(() => {
			this.showDefaultCursor();
			this.hideCustomCursor();
			document.querySelector('.js-cursor-nav-left').classList.add('is-invisible');
			document.querySelector('.js-cursor-nav-right').classList.add('is-invisible');
		}, 200);
	}

	cursorDragOver() {
		this.hideDefaultCursor();
		this.showCustomCursor();
		this.showCursorFigureDrag();
	}

	cursorDragOut() {
		this.hideCursorFigureDrag();
		setTimeout(() => {
			this.showDefaultCursor();
			this.hideCustomCursor();
		}, 200);
	}

	cursorDragActiveIn() {
		this.figureDrag.classList.add('cursor__drag--active');
	}

	cursorDragActiveOut() {
		this.figureDrag.classList.remove('cursor__drag--active');
	}

	/** Remove all hover states */
	resetCursor() {
		this.cursorNavOut();
		this.cursorDragOut();
	}

	/** Toggle cursor hover state by mouseover and mouseout events */
	toggleHoverState() {
		if (this.hoveredNav.length) {
			this.hoveredNav.forEach(item => {
				item.addEventListener('mousemove', () => {
					this.cursorNavOver();
				});
				item.addEventListener('mouseleave', () => {
					this.cursorNavOut();
				});
			});
		}
		if (this.hoveredNavLeft.length) {
			this.hoveredNavLeft.forEach(item => {
				item.addEventListener('mousemove', () => {
					this.cursorNavLeftOver();
				});
			});
		}
		if (this.hoveredNavRight.length) {
			this.hoveredNavRight.forEach(item => {
				item.addEventListener('mousemove', () => {
					this.cursorNavRightOver();
				});
			});
		}
		if (this.hoveredDrag.length) {
			this.hoveredDrag.forEach(item => {
				item.addEventListener('mousemove', () => {
					this.cursorDragOver();
				});
				item.addEventListener('mouseleave', () => {
					this.cursorDragOut();
				});
				item.addEventListener('mousedown', () => {
					this.cursorDragActiveIn();
				});
				item.addEventListener('mouseup', () => {
					this.cursorDragActiveOut();
				});
			});
		}
	}

	scrollDetect(elems, trueFunc, falseFunc) {
		elems.forEach((el, i) => {

			let isFixed = false;
			let currentEl = el;

			while (true) {
				if (getComputedStyle(currentEl).position == 'fixed') {
					isFixed = true;
					break;
				}
				currentEl = currentEl.parentElement;
				if (currentEl.parentElement.matches('.js-page-transition')) break;
			}

			if (isFixed) return;

			const coords = el.getBoundingClientRect();
			const coordLeftTrue = this.cursorLeftPosition > coords.left && this.cursorLeftPosition < coords.right;
			const coordTopTrue = this.cursorTopPosition > coords.top && this.cursorTopPosition < coords.bottom;

			if (coordLeftTrue && coordTopTrue) {
				if (trueFunc) trueFunc();
			}
			else {
				if (falseFunc) falseFunc();
			}

		});
	}

	/** Toggle cursor hover state by scroll event */
	toggleHoverStateOnScroll() {
		window.addEventListener('scroll', () => {

			let isNav = false;
			let isDrag = false;

			this.scrollDetect(this.hoveredNav, () => {
				isNav = true;
			}, () => {
				isNav = false;
			});

			this.scrollDetect(this.hoveredDrag, () => {
				isDrag = true;
			}, () => {
				isDrag = false;
			});

			if (isNav) {
				this.cursorNavOver();
			} else if (isDrag) {
				this.cursorDragOver();
			} else {
				this.cursorNavOut();
				this.cursorDragOut();
			}

		});
	}

	/** Start custom cursor working */
	init() {
		if (!isTouch()) {
			this.moveCursor();
			// this.toggleHoverStateOnScroll();
			this.resetCursor();
			this.toggleHoverState();
		}
	}
}
