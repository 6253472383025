import { scrollTo } from './helpers/scroll-to';
import Cursor from './components/common/Cursor';
import accordeon from './helpers/accordeon';
import form from './components/common/form';
import bookingBtn from './components/common/booking-btn';
import booking from './components/common/booking';
import modal from './components/common/modal';
import ticker from './components/sections/ticker';
import homeDineSlider from './components/sections/home-dine-slider';
import cardsCarousel from './components/sections/cards-carousel';
import imagedDetailsSlider from './components/sections/imaged-details-slider';
import roomHeroSlider from './components/sections/room-hero-slider';
import fullWidthSlider from './components/sections/full-width-slider';
import otherRooms from './components/sections/other-rooms';
import asideAnchors from './components/sections/aside-anchors';
import videoBg from './components/sections/video-bg';
import imagedDescriptionLogos from './components/sections/imaged-description-logos';
import map from './components/sections/map';

export default () => {
	scrollTo({
		offset: 100
	});
	accordeon();
	form();
	bookingBtn();
	booking();
	modal();
	ticker();
	homeDineSlider();
	cardsCarousel();
	imagedDetailsSlider();
	roomHeroSlider();
	fullWidthSlider();
	otherRooms();
	asideAnchors();
	videoBg();
	imagedDescriptionLogos();
	map();
	const cursor = new Cursor();
	cursor.init();
};
