export default () => {

	const opensModal = document.querySelectorAll('.js-open-modal');
	const closesModal = document.querySelectorAll('.js-close-modal');

	if (!opensModal.length || !closesModal.length) return;

	opensModal.forEach(openModal => {

		const id = openModal.getAttribute('data-open-modal');
		const modal = document.querySelector(`[data-modal="${id}"]`);

		openModal.addEventListener('click', () => {
			modal.classList.remove('is-invisible');
			document.body.classList.add('no-scroll');
		});

	});

	closesModal.forEach(closeModal => {

		closeModal.addEventListener('click', () => {
			const modal = closeModal.closest('[data-modal]');
			modal.classList.add('is-invisible');
			document.body.classList.remove('no-scroll');
		})

	});

}
