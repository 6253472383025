import { gsap } from 'gsap';
import customSelect from './../../helpers/custom-select';
import datepicker from 'js-datepicker';

export default () => {

	customSelect({
		dropdownProps: {
			showElements: 6,
			duration: 0.3
		}
	});

	countInput();

	selectDate();

}

function countInput() {

	const fields = document.querySelectorAll('.js-count-input');
	if (!fields.length) return;

	fields.forEach(field => {

		const input = field.querySelector('input');
		const plus = field.querySelector('.js-count-plus');
		const minus = field.querySelector('.js-count-minus');

		const min = input.min ? +input.min : 0;
		const max = input.max ? +input.max : Infinity;
		const step = input.step ? +input.step : 1;

		let currValue = +input.value;

		plus.addEventListener('click', () => {
			currValue += step;
			if (currValue > max) currValue = max;
			input.value = currValue;
		});

		minus.addEventListener('click', () => {
			currValue -= step;
			if (currValue < min) currValue = min;
			input.value = currValue;
		});

	});

}

function selectDate() {
	const selStart = '.js-datepicker-start';
	const selEnd = '.js-datepicker-end';
	if (!document.querySelector(selStart) || !document.querySelector(selEnd)) return;

	let pickerStart;
	let pickerEnd;

	const svgLeft = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20"><path d="M12 .2C9.2 3.4 6.2 8.8 5.2 10c1 1.2 4 6.6 6.8 9.8l-.2.2C9 16.8.4 10.4 0 10c.4-.4 9-6.8 11.8-10l.2.2z"/></svg>`;
	const svgRight = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20"><path d="M0 19.8c2.8-3.2 5.8-8.6 6.8-9.8-1-1.2-4-6.6-6.8-9.8L.2 0C3 3.2 11.6 9.6 12 10c-.4.4-9 6.8-11.8 10l-.2-.2z"/></svg>`;

	const addArrows = instance => {
		instance.calendarContainer.querySelector('.qs-left').innerHTML = svgLeft;
		instance.calendarContainer.querySelector('.qs-right').innerHTML = svgRight;
	};

	const options = {
		position: 'c',
		disableYearOverlay: true,
		showAllDates: true,
		alwaysShow: true,
		formatter: (input, date, instance) => {
			const month = date.getMonth() + 1;
			const day = date.getDate();
			const fullYear = date.getFullYear();
			const year = `${fullYear}`.slice(2);
			input.parentElement.querySelector('.js-datepicker-hidden').value = `${month}/${day}/${fullYear}`;
			input.value = `${month} - ${day} - ${year}`;
		},
		onMonthChange: addArrows,
	};

	const today = new Date();
	const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

	const defaultOnSelect = (instance, date) => {
		closePicker(instance.calendarContainer);
		addArrows(instance);
	}

	const optionsStart = Object.assign({
		minDate: today,
		onSelect: (instance, date) => {
			defaultOnSelect(instance, date)

			if (date) {
				pickerEnd.setMin(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1))
			}
			else {
				pickerEnd.setMin(tomorrow)
			}

			addArrows(pickerEnd)
		}
	}, options);

	const optionsEnd = Object.assign({
		minDate: tomorrow,
		onSelect: (instance, date) => {
			defaultOnSelect(instance, date)

			if (date) {
				pickerStart.setMax(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1))
			}
			else {
				pickerStart.setMax()
			}

			addArrows(pickerStart)
		}
	}, options);

	pickerStart = datepicker(selStart, optionsStart);
	pickerEnd = datepicker(selEnd, optionsEnd);

	document.querySelectorAll(`${selStart}, ${selEnd}`).forEach(btn => {

		let picker;
		if (btn.matches(selStart)) {
			picker = pickerStart;
		}
		else {
			picker = pickerEnd;
		}

		const calendar = picker.calendarContainer;

		btn.addEventListener('click', e => {
			e.stopPropagation();

			gsap.to(calendar, {
				height: 'auto',
				duration: 0.3,
				ease: "power1.out",
			})
		})

		document.addEventListener('click', e => {
			if (e.target.closest('.qs-controls') || e.target.closest('.qs-squares')) return;

			closePicker(calendar);
		})

	});

	function closePicker(calendar) {
		gsap.to(calendar, {
			height: 0,
			duration: 0.3,
			ease: "power1.out",
		})
	}

	document.querySelectorAll('.qs-left').forEach(btn => {
		btn.insertAdjacentHTML('beforeend', svgLeft);
	});

	document.querySelectorAll('.qs-right').forEach(btn => {
		btn.insertAdjacentHTML('beforeend', svgRight);
	});
}
