export default () => {

	const logos = document.querySelectorAll('.js-imaged-description-logo');
	if (!logos.length) return;

	setWidthes();
	window.addEventListener('resize', setWidthes);

	function setWidthes() {

		logos.forEach(logo => {

			logo.style.width = 'auto';
			const originWidth = logo.offsetWidth;
			const mobileWidth = originWidth * 0.66;

			if (window.innerWidth > 1054) {
				logo.style.width = '';
			} else {
				logo.style.width = mobileWidth + 'px';
			}

		});

	}

}
